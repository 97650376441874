import React from 'react';
import rick from '../bewerbungsfoto.jpg';
import '../App.css';
import { SocialIcon } from 'react-social-icons';
import { SkillBars } from 'react-skills';

function About(setter) {


  function getAge(){
    const todayDate = new Date();
    const today = [todayDate.getFullYear(), todayDate.getMonth() + 1, todayDate.getDate()] // January is 0
    const year_born = [2002, 2, 15] 
    const difference = [0, 0, 0]

    for (let i = 0; i < 3; i++){
      difference[i] = today[i] - year_born[i];
    }
    if ( difference[2] < 0 ){
      difference[1] -= 1;
    }

    if ( difference[1] < 0 ){
      difference[0] -= 1
    }

    return difference[0];;
  }

  let age = getAge();

  const aboutText = ["# I am", "# Ich bin", " years old.", " Jahre alt", "#Current Occupation: ", "#Momentane Beschäftigung"]
  const occupation = ["4th Year Apprenticeship in IT Application development EFZ", "4. Lehrjahr als Informatiker Applikationsentwickler EFZ"]
  const skillExplanation = ["0% - 20%: Novice | 21% - 40%: Beginner | 41% - 60%: Intermediate | 61% - 80%: Advanced | 81% - 100%: Expert", "0% - 20%: Neueinsteiger | 21% - 40%: Anfänger | 41% - 60%: Mittleres Fähigkeitsniveau | 61% - 80%: Fortgeschritten | 81% - 100%: Experte"]

  // https://kevincastejon.github.io/react-skills/documentation/
  const skillsData = [
    {
      name: 'ReactJS',
      level: 60,
      color: '#5CD0EE',
      levelProgress: true,
    },
    {
      name: 'HTML',
      level: 60,
      color: '#D84924'
    },
    {
      name: 'CSS',
      level: 60,
      color: '#146CAD'
    },
    {
      name: 'Python',
      level: 60,
      color: '#F29D00'
    },
    {
      name: 'SQL',
      level: 60,
      color: '#006F88'
    },
    {
      name: 'Linux',
      level: 55,
      color: '#D24413'
    },
    {
      name: 'JavaScript',
      level: 52,
      color: '#EAD41C'
    },
    {
      name: 'TypeScript',
      level: 45,
      color: '#2F74C0'
    },
    {
      name: 'Docker',
      level: 45,
      color: '#2496ED'
    },
    {
      name: 'Jira',
      level: 44,
      color: '#2D88FF'
    },
    {
      name: 'Confluence',
      level: 44,
      color: '#186DE4'
    },
    {
      name: 'Abacus',
      level: 42,
      color: '#DE323C'
    },
    {
      name: 'Scrum',
      level: 42,
      color: '#8B6FAB'
    },
    {
      name: 'Git',
      level: 40,
      color: '#E44C30'
    },
    {
      name: 'Java',
      level: 38,
      color: '#E97B18'
    },
    {
      name: 'SCSS',
      level: 35,
      color: '#D2699E'
    },
    {
      name: 'C#',
      level: 30,
      color: '#92458C'
    },
    {
      name: 'Wordpress',
      level: 20,
      color: '#1F6F92'
    },
    {
      name: 'Metasploit',
      level: 15,
      color: '#0B72B0'
    },
    {
      name: 'Microservices',
      level: 10,
      color: '#F85B1A'
    },
    {
      name: 'Machinelearning',
      level: 6,
      color: '#FF421B'
    },
    {
      name: 'C++',
      level: 5,
      color: '#1D659C'
    },
  ]

  return (
    <div id="about">
      <div className="container2">
        <img src={rick} alt="Bewerbungsfoto" id="aboutImage" />

        <ul id="aboutme">
          <li>
            {aboutText[setter.language]} { age } {aboutText[setter.language+setter.languageAmmount]}
          </li>
          <li>
            {aboutText[setter.language+setter.languageAmmount*2]} {occupation[setter.language]}
          </li>
        </ul>
      </div>

      <div id="skillbar">
        <p>Skills: </p>
        <p style={{fontSize: '18px'}} > { skillExplanation[setter.language] } </p>
        <SkillBars spacing={20} skills={skillsData} />
      </div>

      <p>Socials:</p>
      <div className="container" id="socials">
        { /* <SocialIcon id="socialIcons" url="https://raccoongamestudios.itch.io/" fgColor="#ffffff" /> TODO: Fix all the games so that they are presentable */ }
        { /* <SocialIcon id="socialIcons" url="https://www.instagram.com/raccoon_game_studios/" bgColor="#F03D5B" fgColor="#6266E1" /> */ }
        <SocialIcon id="socialIcons" url="https://github.com/LorisThierryHutter" fgColor="#ffffff" bgColor="#15181C" />
        <SocialIcon id="socialIcons" url="https://www.linkedin.com/in/loris-thierry-hutter/" fgColor="#ffffff" />
        <SocialIcon id="socialIcons" url="https://stackoverflow.com/users/14103392/loristhierryhuetter" bgColor="#E87A22" fgColor="#ffffff" />
        <SocialIcon id="socialIcons" url="https://www.sololearn.com/profile/830484" fgColor="#0A3267" bgColor="#E36F40" />
      </div>
    </div>
  );
}

export default About;
