import './App.css';

import Home from './sections/Home';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Experimenthome from './components/Experiments/Experimenthome'
import { Navigation } from './sections/Home';

function App() {



  return (
    <BrowserRouter>
    <div>
      { /* <Navigation /> */ }
      <Switch>
        <Route path="/" component={Home} exact />
      </Switch>
    </div>
  </BrowserRouter>
  );
}

export default App;
